import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dateWithFormatOrNa, toUSD } from '../helpers/formatters';

// Import assets
import scheduledImg from '../assets/images/scheduled.png';

// Import styles
import '../styles/modules/load-account.css';

class LoanAccount extends Component {

  /**
   * Render the last payment information.
   */
  renderLastPayment() {
    const { selectedLoanAccount } = this.props;
    
    if (this.props.hideLastPmtInfo) {
      return (
        <React.Fragment>
          <div className="line-height-15">&nbsp;</div>
        </React.Fragment>
      );
    }

    if (selectedLoanAccount.daysPastDue == 0 && selectedLoanAccount.totalAmtDue > 0 && selectedLoanAccount.oldestDueDt != null) {
      return (
        <React.Fragment>
          <div className="line-height-15">
            <span className="money money--block">
              {toUSD(selectedLoanAccount.lastPmtAmt)}
            </span>
            <span className="load-account__money-legend">
              {this.props.whiteLabel.labels["Last Payment"]}
            </span>
            <span className="load-account__money-date">
              {selectedLoanAccount.lastPmtDt
                ? dateWithFormatOrNa(selectedLoanAccount.lastPmtDt.date)
                : "N/A"}
            </span>
          </div>
        </React.Fragment>
      );
    }

    return  (
      <React.Fragment>
        <div className="line-height-15">
          <span className="money money--block">
            {toUSD(selectedLoanAccount.lastPmtAmt)}
          </span>
          <span className="load-account__money-legend">
            {this.props.whiteLabel.labels["Last Payment"]}
          </span>
          <span className="load-account__money-date">
            {selectedLoanAccount.lastPmtDt
              ? dateWithFormatOrNa(selectedLoanAccount.lastPmtDt.date)
              : "N/A"}
          </span>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Render the next payment information.
   */
  renderNextPayment() {
    const { selectedLoanAccount } = this.props;

    if (this.props.hideNextPmtInfo) {
      return (
        <React.Fragment>
          <div className="line-height-15">&nbsp;</div>
        </React.Fragment>
      );
    }

    if (selectedLoanAccount.daysPastDue == 0 && selectedLoanAccount.totalAmtDue > 0 && selectedLoanAccount.oldestDueDt != null) {
      return (
        <React.Fragment>
          <div className="line-height-15">
            {selectedLoanAccount.daysPastDue > 0 ? (
              <React.Fragment>
                <span className="money money--block money--red">
                  {toUSD(selectedLoanAccount.totalAmtDue)}
                </span>
                <span className="load-account__money-legend">
                  {this.props.whiteLabel.labels["past_due_pmt_date"]}
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span className="money money--block money--green">
                  {toUSD(selectedLoanAccount.calNextPayment)}
                </span>
                <span className="load-account__money-legend">
                  {this.props.whiteLabel.labels["Due Date"]}
                </span>
              </React.Fragment>
            )}

            <span className="load-account__money-date">
              {selectedLoanAccount.oldestDueDt
                ? dateWithFormatOrNa(selectedLoanAccount.oldestDueDt.date)
                : "N/A"}
            </span>
          </div>
        </React.Fragment>
        );
    }
        
    return (
      <React.Fragment>
        <div className="align-right line-height-15">
          {selectedLoanAccount.daysPastDue > 0 ? (
            <React.Fragment>
              <span className="money money--block money--red">
                {toUSD(selectedLoanAccount.totalAmtDue)}
              </span>
              <span className="load-account__money-legend">
                {this.props.whiteLabel.labels["past_due_pmt_date"]}
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span className="money money--block money--green">
                {toUSD(selectedLoanAccount.calNextPayment)}
              </span>
              <span className="load-account__money-legend">
                {this.props.whiteLabel.labels["current_pmt_date"]}
              </span>
            </React.Fragment>
          )}

          <span className="load-account__money-date">
            {selectedLoanAccount.newOldestDate
              ? dateWithFormatOrNa(selectedLoanAccount.newOldestDate.date)
              : "N/A"}
          </span>
        </div>
      </React.Fragment>
    );
  }

  /**
   * Render the last and next payments information.
   */
  renderLastAndNextPayments() {
    return (
      <div className="flex-block flex-block--space-between">
        { this.renderLastPayment() }
        { this.renderNextPayment() }
      </div>
    );
  }

  /**
   * Render the component view.
   */
  render() {
    let { selectedLoanAccount } = this.props;

    if (!selectedLoanAccount) {
      return null;
    }

    let params = {
      displayTile: !this.props.displayScheduledStatusTileAtTopLeft,
      hidePaidOff: this.props.hidePaidOff,
      scheduledWhiteLabel: (this.props.whiteLabel.labels['Scheduled']) ? this.props.whiteLabel.labels['Scheduled'] : 'Scheduled'
    };

    return (
      <div className="loan-account">
        {this.props.header}

        <div className="card">
          {this.props.collateral}
          <div>
            {this.props.profile}
            <div className="load-account__payment-data-wrapper">
              <div className="load-account__profile">
                <h5 className="loan-account__borrower">
                  { (selectedLoanAccount.isBorrower)
                      ? `${selectedLoanAccount.borrower.firstName} ${selectedLoanAccount.borrower.lastName}`
                      : `${selectedLoanAccount.borrower.coBorrower.firstName} ${selectedLoanAccount.borrower.coBorrower.lastName}`
                  }
                </h5>

                {this.props.renderLoanStatusOrSchedule(selectedLoanAccount, params)}
              </div>

              {/**
                ** Last payment & Next payment.
                **/}
              {
                (this.props.hideLastNextPmtInfo) ? null : this.renderLastAndNextPayments()
              }

              {/**
                ** Render the payment or schedule Form.
                **/}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    client: state.client.data
  }
}

export default connect(mapStateToProps)(LoanAccount);
