import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';
import { PHONE_NUMBER } from '../../constants/regex';
import { isEmail } from 'validator';

const renderInput = ({input, label, type, htmlFor, maxLength, autoComplete, meta: { touched, error }}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">{label}</label>
    <input className="form-group__input" id={htmlFor} {...input} type={type} 
      maxLength={maxLength} autoComplete={autoComplete} />
    {touched && error && <span className="form-group__error">{error}</span>}
  </div>
);

const validate = (values, _parent) => {
  const errors = {};
  if (!values.contact || (!PHONE_NUMBER.test(values.contact) && !isEmail(values.contact))) {
    errors.contact = _parent.whiteLabel.labels['Invalid email or phone number'];
  }

  return errors;
};

class ForgotPasswordForm extends Component {
  /**
   * Render the component view.
   */
  render() { 
    const { valid } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <Field name="contact" type="text" htmlFor="contact" component={renderInput} label={this.props.whiteLabel.labels['Email Address']} />

        <div className="form-group">
          <PayixButton className="button--full" type="submit"  disabled={!valid}>
              {this.props.whiteLabel.labels['Submit']}
          </PayixButton>
        </div>
      </form>
    )
  }
}

ForgotPasswordForm = reduxForm({
  form: 'forgotPasswordForm',
  validate,
})(ForgotPasswordForm)
 
export default ForgotPasswordForm;