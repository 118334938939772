import React, { Component } from 'react';

// Import components
import Dropdown from '../../../components/application/dropdown/index';
import DropdownItem from '../../../components/application/dropdown/item';
import { isEmpty } from '../../../helpers/app';

class Collateral extends Component {
  /**
   * Handle the event to delete a loan account.
   */
  handleDelete = () => {
    this.props.onDelete();
  }

  /**
   * Render the collateral information.
   */
  renderCollateral(params) {
    let { year, make, model } = this.props;

    if (isEmpty(year) && isEmpty(make) && isEmpty(model)) {
      return <span className="loan_account__no-collateral">{this.props.renderLoanStatusOrSchedule(this.props.selectedLoanAccount, params)}</span>
    }

    return (
      <div>
        <span className="loan-account__collateral">
          {`${this.props.year} ${this.props.make}`}
        </span>
        <h5 className="loan_account__model">{this.props.model}</h5>
      </div>
    )
  }

  /**
   * Render the component view.
   */
  render() {
    let { year, make, model } = this.props;
    let displayTileNoCollateral = false;
    if (year !== '' && make !== '' && model !== '') {
      displayTileNoCollateral = true;
    }

    let params = {
      displayTile: this.props.displayScheduledStatusTileAtTopLeft,
      hidePaidOff: this.props.hidePaidOff,
      scheduledWhiteLabel: (this.props.whiteLabel.labels['Scheduled']) ? this.props.whiteLabel.labels['Scheduled'] : 'Scheduled'
    };

    return (
      <div>
        <div className="loan-account__header">
          {this.renderCollateral(params)}

          {/**
           ** Options
          **/}
          {this.props.withOptions ? (
            <div className="load-account__options-wrapper">
              <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
                <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
              }>
                <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleDelete}>
                  {this.props.whiteLabel.labels['Delete Loan']}
                </DropdownItem>
              </Dropdown>
            </div>
          ) : null}
        </div>
        {(this.props.displayScheduledStatusTileAtTopLeft === true && displayTileNoCollateral === true) && 
          (<div className="loan-account__header">
            {this.props.renderLoanStatusOrSchedule(this.props.selectedLoanAccount, params)}
          </div>)
        }
      </div>
    );
  }
}

Collateral.defaultProps = {
  withOptions: true
}

export default Collateral;